<template>
  <el-dialog
    title="观看记录"
    class="customize-el-dialog"
    :visible.sync="visible"
    width="1000"
    @open="getDataList"
  >
    <div class="header"><span /><el-button type="text" @click="handleExport">导出数据</el-button></div>
    <el-table height="400" :data="tableData">
      <el-table-column label="上线时间" width="160" prop="onlineTime" />
      <el-table-column label="认证状态" prop="certifyStatus" />
      <el-table-column label="姓名" prop="doctorName" />
      <el-table-column label="职称" prop="titleName" />
      <el-table-column label="科室" prop="department" />
      <el-table-column label="机构名称" prop="hospital" />
      <el-table-column label="观看时长" prop="viewSeconds" />
    </el-table>
    <el-pagination
      :current-page="pagination.pageNo"
      :total="pagination.total"
      :page-sizes="pagination.sizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :hide-on-single-page="false"
      class="pagination"
      style="margin-top: 20px;"
      background
      @size-change="onSizeChange"
      @current-change="onCurrentChange"
    />
  </el-dialog>
</template>
<script>
import paginationMixin from '@/mixins/pagination'
import {JsonToExcel, secondsToHMS} from '@/utils/util'
export default {
  name: 'WatchRecordDialog',
  mixins: [paginationMixin],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    challengeId: {
      type: String,
      default: ''
    },
    liveId: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      tableData: [],
      loading: false
    }
  },
  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    getDataList() {
      const params = this.getParams({
        liveId: this.liveId,
        challengeId: this.challengeId,
        keyWord: this.keyWord
      })
      this.loading = true
      this.$axios.get(this.$API.getWatchRecordList, { params }).then(res => {
        this.tableData = res.data.data.map(item => {
          item.viewSeconds = secondsToHMS(item.viewSeconds)
          item.certifyStatus = this.certifyStatusText(item.certifyStatus)
          return item
        })
        this.pagination.total = res.data.totalCount
      }).catch(error => {
        this.tableData = []
        this.$message.error(error.message)
      }).finally(() => {
        this.loading = false
      })
    },
    certifyStatusText(status) {
      const statusMap = {
        0: '未认证',
        1: '已认证',
        2: '审核中',
        3: '未通过'
      }
      return statusMap[status]
    },
    handleExport() {
      if (!this.tableData.length) {
        this.$message.error('暂无需要导出的数据')
        return
      }
      const json = this.tableData.map(item => {
        return ['onlineTime', 'certifyStatus', 'doctorName', 'titleName', 'department', 'hospital', 'viewSeconds'].map(column => item[column])
      })
      const options = ['上线时间', '认证状态', '姓名', '职称', '科室', '机构名称', '观看时长（s）']
      if (json.length > 0) {
        JsonToExcel(`${this.title}_观看记录`, [options, ...json])
      } else {
        this.$message.error('暂无需要导出的数据')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
