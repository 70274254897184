<template>
  <div>
    <el-table
      ref="multipleTable"
      :data="list"
      class="customize-el-table"
      style="width: 100%;"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        v-if="isShowSelect"
        type="selection"
        width="55"
      />
      <el-table-column
        fixed
        prop="title"
        label="作品名称"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          <el-button
            type="text"
            @click="handleOpera(row, 'play')"
          >
            {{ row.title }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        prop="challengeTitle"
        label="活动名称"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column prop="duration" label="视频时长" align="center">
        <template #default="{ row }">
          {{ Math.floor(row.duration / 60) }}:{{ row.duration % 60 }}
        </template>
      </el-table-column>
      <el-table-column prop="author" label="作者" align="center" />
      <el-table-column
        prop="mustViewMin"
        label="审核要求"
        align="center"
        min-width="100"
      >
        <template #default="{ row }">
          <span
            class="rule-detail"
          >
            <i v-if="row.isViewNumAndTimeOk" class="el-icon-success" />
            <i v-else class="el-icon-warning" />
            观看满{{ row.mustViewMin || 0 }}分钟人数大于5人
          </span>
        </template>
      </el-table-column>
      <el-table-column
        width="180"
        prop="podcastReviewStatus"
        label="内容审核状态"
        align="center"
      >
        <template #default="{ row }">
          <el-popover
            v-if="row.podcastReviewStatus === -2 || row.podcastReviewStatus === 4"
            placement="top-start"
            title="原因："
            :width="200"
            trigger="hover"
            :content="row.failReason"
          >
            <template #reference>
              <span :style="{ color: getColor(row.podcastReviewStatus) }">{{ row.podcastReviewStatusDesc }}</span>
            </template>
          </el-popover>
          <span v-else :style="{ color: getColor(row.podcastReviewStatus) }">{{ row.podcastReviewStatusDesc }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="managerName"
        sort-by="totalCount"
        sortable
        width="180"
        label="已完成/所有任务"
        align="center"
      >
        <template #default="{ row }">
          <span>{{ row.completeCount }}/{{ row.totalCount }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="academicSourceType === 'down'"
        label="创建组织"
        prop="createOrgName"
        show-overflow-tooltip
        align="center"
      />
      <el-table-column
        align="center"
        label="操作"
        fixed="right"
        min-width="150"
      >
        <template #default="{ row }">
          <el-row>
            <el-button
              v-if="(row.podcastReviewStatus === 2 ||row.podcastReviewStatus === 3 || row.podcastReviewStatus === 5) && !isMobile"
              type="text"
              @click="handleOpera(row, 'edit')"
            >
              编辑
            </el-button>
            <el-button
              v-if="row.podcastReviewStatus === 2 && !row.isPreReimbursement && row.isViewNumAndTimeOk"
              type="text"
              @click="handleOpera(row, 'buy')"
            >
              内容合适
            </el-button>
            <el-button
              v-if="row.podcastReviewStatus === 2 && !row.isPreReimbursement && row.isViewNumAndTimeOk"
              type="text"
              @click="handleOpera(row, 'reject')"
            >
              内容不合适
            </el-button>
            <el-button
              type="text"
              @click="handleOpera(row, 'assign')"
            >
              分配
            </el-button>
            <el-button
              type="text"
              @click="handleOpera(row, 'withdraw')"
            >
              撤回
            </el-button>
            <el-button
              v-if="!isMobile"
              type="text"
              @click="handleOpera(row, 'url')"
            >
              观看链接
            </el-button>
            <el-button
              type="text"
              @click="handleOpera(row, 'record')"
            >
              观看记录
            </el-button>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <!-- 编辑弹窗 -->
    <LivePlayBack
      v-model="editDialog"
      :live-id="currentRow.liveId"
      :live-status="currentRow.podcastStatus"
    />
    <!-- 观看视频 -->
    <VideoPlayModal
      :visible="playVideoDialog"
      :title="currentRow.title"
      :video-url="currentRow.videoUrl"
      @onClose="onCloseModal"
    />
    <!-- 撤回弹窗 -->
    <RecallSceneCodeModal
      :visible="withdrawDialog"
      :scene-id="currentRow.liveId"
      :scene-type="sceneType"
      @onClose="onCloseModal"
    />
    <!-- 二维码弹窗 -->
    <QRCodeModal
      :visible="showQRCodeDialog"
      :url="currentRow.doctorClientUrl"
      :qr-code="currentRow.doctorClientQRCode"
      @onClose="onCloseModal"
    />

    <!-- 观看记录弹窗 -->
    <WatchRecordDialog
      v-model="recordDialog"
      :live-id="currentRow.liveId"
      :challenge-id="currentRow.challengeId"
      :title="currentRow.title"
    />
  </div>
</template>
<script>
import RecallSceneCodeModal from '@/bizComponents/RecallSceneCodeModal'
import QRCodeModal from '../components/QRCodeModal.vue'
import VideoPlayModal from '@/bizComponents/VideoPlayModal'
import LivePlayBack from '../live-console/components/live-playback'
import WatchRecordDialog from '../components/WatchRecordDialog.vue'
import { isMobile } from '../../../utils/util'
export default {
  name: 'WorkListTable',
  components: {
    RecallSceneCodeModal,
    QRCodeModal,
    VideoPlayModal,
    LivePlayBack,
    WatchRecordDialog
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    isShowSelect: {
      type: Boolean,
      default: false
    },
    academicSourceType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      editDialog: false,
      withdrawDialog: false,
      playVideoDialog: false,
      sceneType: 'MEDICAL_PODCAST',
      sceneId: '',
      videoTitle: '',
      videoPreviewUrl: '',
      doctorClientUrl: '',
      doctorClientQRCode: '',
      currentRow: {
        challengeId: '',
        doctorClientUrl: '',
        doctorClientQRCode: '',
        liveId: '',
        videoUrl: '',
        title: '',
        podcastStatus: 0
      },
      showQRCodeDialog: false,
      isMobile: isMobile(),
      recordDialog: false
    }
  },
  computed: {

  },
  watch: {
    isShowSelect(val) {
      if (val) this.toggleSelection()
    }
  },
  mounted() {
  },
  methods: {
    handleSelectionChange(val) {
      this.$emit('onSelect', val)
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    handleOpera(row, type) {
      this.currentRow = {
        doctorClientUrl: row.doctorClientUrl || '',
        doctorClientQRCode: row.doctorClientQRCode || '',
        liveId: row.liveId || '',
        videoUrl: row.videoUrl || '',
        title: row.title || '',
        challengeId: row.challengeId,
        podcastStatus: row.podcastStatus || 0
      }
      if (type === 'record') {
        this.recordDialog = true
      } else if (type === 'edit') {
        this.editDialog = true
      } else if (type === 'assign') {
        // 分配
        if (row.isAttribute) {
          this.$router.push({
            path: `/academic/scene/MEDICAL_PODCAST/rule/${row.liveId}`,
            query: {
              disabledBack: false
            }
          })
        } else {
          this.$router.push({
            path: `/academic/scene/MEDICAL_PODCAST/edit/${row.liveId}`,
            query: {
              challengeId: row.challengeId
            }
          })
        }
      } else if (type === 'buy') {
        // 内容合适
        this.$confirm('确认内容合适？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.confirmContent(row.challengeId, row.liveSpeakerId)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
      } else if (type === 'reject') {
        // 内容不合适，输入框提交拒绝原因
        this.$prompt('', '内容不合适', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /\S/,
          inputErrorMessage: '请输入内容不合适原因'
        }).then(({ value }) => {
          this.failContent(row.challengeId, row.liveSpeakerId, value)
        })
      } else if (type === 'withdraw') {
        // 撤回
        this.withdrawDialog = true
      } else if (type === 'play') {
        if (!row.videoUrl) {
          return this.$message.error('暂无视频')
        }
        this.playVideoDialog = true
      } else if (type === 'url') {
        this.showQRCodeDialog = true
      }
    },
    confirmContent(challengeId, liveSpeakerId) {
      this.$axios.post(this.$API.podcastReviewPass, { challengeId, liveSpeakerId }).then(() => {
        this.$message.success('操作成功')
        this.$emit('on-change')
      }, rea => {
        this.$message.error(rea.message)
      })
    },
    failContent(challengeId, liveSpeakerId, failReason) {
      this.$axios.post(this.$API.podcastReviewFail, { challengeId, liveSpeakerId, failReason }).then(() => {
        this.$message.success('操作成功')
        this.$emit('on-change')
      }, rea => {
        this.$message.error(rea.message)
      })
    },
    onCloseModal() {
      this.withdrawDialog = false
      this.playVideoDialog = false
      this.showQRCodeDialog = false
      this.editDialog = false
      this.$emit('on-change')
    },
    getColor(status) {
      if (status === 5) {
        return '#30C691'
      } else if (status === 2) {
        return '#FB9C35'
      } else if (status === -2 || status === 4) {
        return '#FF4921'
      } else if (status === 1) {
        return '#0F0F0F'
      } else {
        return '#909399'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-icon-success {
  color: #30c691;
  font-size: 16px;
}
.el-icon-warning {
  color: #fb9c35;
  font-size: 16px;
}
</style>
